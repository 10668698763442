@import '../../assets/styles/variables';
.info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
  .wrap {
    max-width: 554px;
    margin: auto;

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      max-width: 588px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      max-width: 956px;
    }
  }
  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 7.5vh;

    &::-webkit-scrollbar-track {
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 8px;

      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        width: 13px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        width: 13px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--game-color);
    }
  }
}

.title {
  color: var(--game-color);
  font-size: 32px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 44px;
    margin: 64px 0;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 44px;
    margin: 64px 0;
  }
}

.subtitle {
  color: var(--game-color);
  font-size: 24px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 32px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin: 64px 0;
  }
}

.row {
  display: flex;
  margin: 16px 0;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .col {
    width: 100%;
    padding: 0 16px;
  }
}

.p {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: left;
  margin: 0 0 10px;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 26px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 26px;
  }

  &.center {
    text-align: center;
  }

  span {
    color: var(--game-color);
  }
}

.image {
  display: flex;
  flex-direction: column;

  img,
  svg {
    display: block;
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
  }

  &__text {
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}

.color {
  color: var(--game-color);
}

.paytable-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0 8px 16px;

    @media screen and (orientation: landscape) {
      width: 33.333%;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      padding: 0 16px 32px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      padding: 0 16px 32px;
    }

    .img {
      display: block;
      width: 50%;
      position: relative;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .content {
      //white-space: nowrap;
      width: 50%;
      padding-left: 8px;
      align-items: flex-start;
      justify-content: center;
      font-size: 14px;
      color: var(--game-color);
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 20px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 20px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }

  &__scatter {
    display: block;
    align-items: center;
    //width: 50%;
    padding: 0 8px 16px;

    @media screen and (orientation: landscape) {
      //width: 33.333%;
    }
    @media screen and (orientation: portrait) {
      width: 50%;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      padding: 0 16px 32px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      padding: 0 16px 32px;
    }
    .image__title {
      color: #fff;
      text-align: center;
      font-size: 32px;
      margin-bottom: 8px;
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 32px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 32px;
      }
    }
    .img {
      position: relative;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .content {
      display: flex;
      white-space: nowrap;
      //width: 50%;
      padding-left: 8px;
      align-items: flex-start;
      justify-content: center;
      font-size: 14px;
      color: var(--game-color);
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 20px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 20px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }
  .additional {
    color: #fff;
    font-size: 16px;
    text-align: center;
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      font-size: 26px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      font-size: 26px;
    }
  }
}

.payLines {
  display: block;
  width: 100%;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
}

.buttonBehaviors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  &__item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #fff;
    padding: 0 8px 16px;
    text-align: center;

    @media screen and (orientation: portrait) {
      width: 50%;
    }

    svg {
      display: block;
      width: 56px;
      height: 56px;
      margin-bottom: 8px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }
    &.start {
      width: 100%;
      svg {
        width: 210px;
        // height: 128px;
      }
    }
    &.spin {
      margin-left: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }
    &.stop {
      margin-right: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }

    @media screen and (orientation: portrait) {
      &.start,
      &.spin,
      &.stop {
        margin: 0 !important;
      }
    }
  }
}

.buy_feature {
  display: flex;
  flex-direction: column;

  &__img {
    display: block;
    width: 100px;
    height: auto;
    margin: auto;

    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      width: 200px;
    }

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      width: 200px;
    }
  }
  &__text {
    position: relative;
    text-align: center;
    font-size: 20px;
    max-width: 65%;
    line-height: 1.2;
    color: #fffdc7;
    text-shadow: -1px -1px 0 #dd1a79, 1px -1px 0 #dd1a79, -1px 1px 0 #dd1a79, 1px 1px 0 #dd1a79;
  }
}
