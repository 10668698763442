$tablet-landscape-min: 1024px;
$tablet-portrait-min: 768px;

$mobile-landscape-min: 667px;
$mobile-landscape-max: 915px;
$mobile-portrait-min: 390px;

:root {
  --game-color: #47FD53;
  --font-family: 'NotoSans-SemiCondensedBold';
  --bottom-height-percent-landscape: 8%;
  --bottom-height-percent-portrait: 8%;
}

@media screen and (max-width: $mobile-landscape-max) and (orientation: landscape) {
  :root {
    --bottom-height-percent-landscape: 8%;
  }
}

@media screen and (min-width: 1181px) and (orientation: landscape) {
  :root {
    --bottom-height-percent-landscape: 5%;
  }
}
